import React from "react";
import { Link } from "gatsby";

import { buttonStyle } from "../components/styles";
import { Base, LimitMaxWidth, SectionHeading, urls } from "../components/layout";
import HeroNoCtaSection from "../components/page-sections/HeroNoCtaSection";
import DemoAndEmployerBannerSection from "../components/page-sections/DemoAndEmployerBannerSection";
import SEO from "../components/seo";
import heroWebp from "../assets/images/hero-about.webp";
import introPng from "../assets/images/intro-about.png"
import introAvif from "../assets/images/intro-about.avif"
import introWebp from "../assets/images/intro-about.webp"

import anyaPng from "../assets/images/team-anya.png";
import chantellePng from "../assets/images/team-chantelle.png";
import karolinaPng from "../assets/images/team-karolina.png";
import tonyPng from "../assets/images/team-tony.png";
import anyaAvif from "../assets/images/team-anya.avif";
import chantelleAvif from "../assets/images/team-chantelle.avif";
import karolinaAvif from "../assets/images/team-karolina.avif";
import tonyAvif from "../assets/images/team-tony.avif";
import anyaWebp from "../assets/images/team-anya.webp";
import chantelleWebp from "../assets/images/team-chantelle.webp";
import karolinaWebp from "../assets/images/team-karolina.webp";
import tonyWebp from "../assets/images/team-tony.webp";
import joinUsPng from "../assets/images/join-us.png";
import joinUsAvif from "../assets/images/join-us.avif";
import joinUsWebp from "../assets/images/join-us.webp";

const bottomBanner = <DemoAndEmployerBannerSection title="People are your asset." subtitle="So let's start a conversation." showEmployerReferral={true} />

export const Head = () => <SEO title="Syrona Health | About" />

const AboutPage: React.FC = () => <Base bottomBanner={bottomBanner}><AboutContent/></Base>

export default AboutPage;

const AboutContent: React.FC = () => <>
  <HeroNoCtaSection
    heading={<>Personalised Healthcare</>}
    subheading="powered by technology."
    bodyNodes={[
      <div className="text-b1 font-nun text-white">
        <p>Our vision was to fix healthcare as we know it.</p>
        <p>We tackle the underserved, poorly researched areas in healthcare.</p>
        <p>We choose purpose over profit.</p>
      </div>,
    ]}
    image={heroWebp}
  />
  <IntroSection/>
  <TeamSection/>
  <JoinUsSection/>
</>

const IntroSection: React.FC = () => <>
  <div className="py-12">
    <LimitMaxWidth>
      <div className="flex md:space-x-8 max-md:flex-col max-md:space-y-8">
        <picture className="md:w-1/2 object-scale-down" >
          <source srcSet={introAvif} type="image/avif" />
          <source srcSet={introWebp} type="image/webp" />
          <img src={introPng} alt=""/>
        </picture>
        <div className="md:w-1/2 flex flex-col">
          <h3 className="max-sm:text-m-lg-t sm:text-h3 font-dm text-black">
            Our mission is to serve overlooked health journeys.
          </h3>
          <p className="mt-6 max-sm:text-m-b sm:text-s2 font-nun text-black">
            We were patients before we were founders. 
          </p>
          <p className="max-sm:text-m-b sm:text-s2 font-nun text-black">
            Every life-stage brought with it new healthcare challenges.
          </p>
          <p className="mt-6 max-sm:text-m-b sm:text-s2 font-nun text-black">
            This is our life's work: to change the status quo and tackle the underserved areas of healthcare, deal with taboos and shake off the stigma. That's why we built Syrona Health.
          </p>
          <p className="mt-6 max-sm:text-m-b sm:text-b1 font-nun text-black italic">
            - Anya and Chantelle, Co-founders
          </p>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>

const team = [
  {
    name: "Anya Roy",
    title: "Co-Founder",
    avif: anyaAvif,
    webp: anyaWebp,
    png: anyaPng,
  },
  {
    name: "Chantelle Bell",
    title: "Co-Founder",
    avif: chantelleAvif,
    webp: chantelleWebp,
    png: chantellePng,
  },
  {
    name: "Dr Karolina Afors",
    title: "Chief Medical Officer",
    avif: karolinaAvif,
    webp: karolinaWebp,
    png: karolinaPng,
  },
  {
    name: "Tony Powis",
    title: "Non-Executive Director",
    avif: tonyAvif,
    webp: tonyWebp,
    png: tonyPng,
  },
]

const TeamSection: React.FC = () => {
  return <>
    <div className="py-12 bg-gradient-to-r from-syr-main to-syr-snd">
      <LimitMaxWidth>
        <SectionHeading>
          <div className="text-white">Our leadership</div>
        </SectionHeading>
        <p className="py-8 max-sm:text-m-b sm:text-s2 font-nun text-white text-center">
          We're a team of scientists, doctors, developers, designers and dreamers. Our vision is to fight gender inequality in medicine by creating accessible care and contributing to research, all to close the gender health gap by creating health equity.
        </p>
        <div className="flex justify-center">
          <div className="grid max-md:grid-cols-1 md:max-lg:grid-cols-2 lg:grid-cols-4 gap-16">
            {team.map(t => <>
              <div className="w-64 flex flex-col justify-center items-center space-y-4">
                <picture className="w-64 object-scale-down">
                  <source srcSet={t.avif} type="image/avif" />
                  <source srcSet={t.webp} type="image/webp" />
                  <img src={t.png} alt={`${t.name}`}/>
                </picture>
                <div className="text-h4 font-dm text-white text-center">{t.name}</div>
                <div className="text-s2 font-nun text-white text-center">{t.title}</div>
              </div>
            </>)}
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const JoinUsSection: React.FC = () => <>
  <div className="py-12">
    <LimitMaxWidth>
      <div className="px-8 flex max-sm:flex-col justify-center items-center sm:space-x-8">
        <picture className="sm:w-5/12 max-sm:w-9/12 max-sm:order-1 max-sm:mt-8">
          <source srcSet={joinUsAvif} type="image/avif" />
          <source srcSet={joinUsWebp} type="image/webp" />
          <img src={joinUsPng} alt=""/>
        </picture>
        <div className="sm:w-5/12 max-sm:order-0">
          <h3 className="text-h3 font-dm text-black">Want to join our team?</h3>
          <p className="text-b1 font-nun text-syr-nero">
          If you think you're a good fit for our company, check out what positions we currently have open
          </p>
          <div className="mt-8">
            <Link to={urls.careers}>
              <button className={`${buttonStyle} bg-syr-snd text-white`}>
                View careers
              </button>
            </Link>
          </div>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>
